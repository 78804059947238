
import React, { useState, useEffect } from 'react';
import { Navigate, Link } from 'react-router-dom';

import { db } from "../../../config/firebase-config";
import { query, collection, deleteDoc, doc, addDoc, getDoc, getDocs, updateDoc, getCountFromServer, where, orderBy, limit, limitToLast, startAfter, endBefore} from "firebase/firestore";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import validation from '../../../config/validation';

const Contact_form = (props)=>{ 

  const __data = {
    name: '',
    phone: '',
    email: '',		
    message: '',   
    number_1: Math.floor(Math.random() * 10) + 1,   
    number_2: Math.floor(Math.random() * 10) + 1,
    number_3: Math.floor(Math.random() * 10) + 1,      
    total_number: '',   
  }

  const __errors = {
    name: '',
    phone: '',
    email: '',		
    message: '',
    total_number: '',      
  }

  const [data,set_data]  = useState(__data) 
  const [disablebutton, set_disablebutton] 	= useState(false);  
	const [errors,set_errors]     			   		= useState(__errors)  
  const [success,set_success]     			   	= useState(false) 

  const MySwal = withReactContent(Swal)
  
  const handleChange = (e)=>{	
		const field_name  = e.target.name;
		const field_value = e.target.value;
		set_data({...data, [field_name]: field_value})
	}

  const validateForm = ()=>{		
		let errors                = {};  
		let isValid               = true;  

		if(!data.name){
			isValid 			   = false;
			errors.name     = 'Name is required';
		}				
		if(!data.phone){
			isValid 			   = false;
			errors.phone     = 'Phone Number is required';
		}	
		if(!data.email){
			isValid 			   = false;
			errors.email     = 'Email is required';
		}	
    if(data.email && !validation.validateEmail(data.email)){
      isValid 		       = false;
      errors.email       = 'Email is not valid!';
    }		
		if(!data.message){
			isValid 			   = false;
			errors.message   = 'Message is required';
		}	    
    if(!data.total_number){
			isValid 			       = false;
			errors.total_number  = 'This is required';
		}		
    if(data.total_number &&  (parseInt(data.number_1) + parseInt(data.number_2) + parseInt(data.number_3)) != parseInt(data.total_number) ){
			isValid 			       = false;
			errors.total_number  = 'wrong calculation';
		}				    
		set_errors(errors);	
		return isValid;		 
	}

  const handleSubmit = async(e)=>{
		e.preventDefault();   
		if( validateForm() ){
        
        /*
        set_disablebutton(true)        
        const docRef = await addDoc(collection(db, "contact"), {
          name: data.name,                 
          phone: data.phone,       
          email: data.email,       
          message: data.message,  
          status: 0,   
          create_date: new Date(),  
          modified_date: new Date(), 
        });	
        set_data({
          ...__data, 
          number_1: Math.floor(Math.random() * 10) + 1,   
          number_2: Math.floor(Math.random() * 10) + 1   
        }) 
        set_disablebutton(false)
        MySwal.fire({icon: 'success', text:'Your message has been sent. Thank you!', confirmButtonColor: '#3085d6'}) 
        */
       
        set_disablebutton(true)    
            
        let headers = new Headers();			
				//headers.append('x-access-token', 'FGFHGH7HHJKJKJKYYYY');
				let formData = new FormData(e.target);					
				formData.append('status',0); 				
				const res = await fetch(process.env.REACT_APP_USER_API_URL+'/contact',{	
					method: 'POST',  // *GET, POST, PUT, DELETE, etc.
					headers: headers,
					body: formData 			
				});	
        var data = await res.json();
        if(data.status===true){
            set_data({
              ...__data, 
              number_1: Math.floor(Math.random() * 10) + 1,   
              number_2: Math.floor(Math.random() * 10) + 1,
              number_3: Math.floor(Math.random() * 10) + 1     
            }) 
            set_disablebutton(false)
            set_success(true)
            // warning, error, success, info
            //MySwal.fire({icon: 'success', text:'Your message has been sent. Thank you!', confirmButtonColor: '#3085d6'})             
        }
        else{
            set_data({
              ...__data, 
              number_1: Math.floor(Math.random() * 10) + 1,   
              number_2: Math.floor(Math.random() * 10) + 1,
              number_3: Math.floor(Math.random() * 10) + 1   
            }) 
            set_disablebutton(false)
            MySwal.fire({icon: 'error', text:data.status.message, confirmButtonColor: '#3085d6'}) 
        }       
		}			
	}

  if(success){			
    return <Navigate  to='/thank-you' />			
  }	
  
  return (  
    <>  
    
    <form name="contactFrm" id="contactFrm" method="post" className='php-email-form' encType="multipart/form-data" onSubmit={handleSubmit}>    
      
      <div className='row'>
          <div className='col-lg-6 col-md-12 col-12'>
          <div className="form-group">
            <input type="text" className={`form-control ${errors.name ? 'error': ''} `} name="name" id="name" placeholder="Name" 
            value={data.name}
            onChange={handleChange} />
            {errors.name && 
                <div className="error-msg">{errors.name}</div>    
            }  
          </div>
          </div>
          <div className='col-lg-6 col-md-12 col-12'>
          <div className="form-group">
        <input type="text" className={`form-control ${errors.phone ? 'error': ''} `} name="phone" id="phone" placeholder="Phone No." 
        value={data.phone}
        onChange={handleChange} />
        {errors.phone && 
            <div className="error-msg">{errors.phone}</div>    
        }  
      </div>
          </div>
      </div>

      <div className="form-group">
        <input type="text" className={`form-control ${errors.email ? 'error': ''} `} name="email" id="email" placeholder="Email" 
        value={data.email}
        onChange={handleChange} />
        {errors.email && 
            <div className="error-msg">{errors.email}</div>    
        }  
      </div>

      

      <div className="form-group">
      <textarea className={`form-control ${errors.message ? 'error': ''} `} name="message" id='message' rows="5" placeholder="Message" 
      value={data.message} 
      onChange={handleChange} />
      {errors.message && 
            <div className="error-msg">{errors.message}</div>    
      }  
      </div>

      <div className="form-group">
        <div className="d-flex">
            <div style={{paddingTop:"5px",paddingRight:"10px",fontSize:"20px"}}>
              {`${data.number_1} + ${data.number_2} + ${data.number_3}  = `}         
            </div>
            <div>
            <input type="number" className={`form-control ${errors.total_number ? 'error': ''} `} style={{width:"100px"}} name="total_number" id="total_number" placeholder="" 
            value={data.total_number}
            onChange={handleChange} />    
            </div>      
          </div>
          {errors.total_number && 
              <div className="error-msg" style={{paddingTop:"10px"}}>{errors.total_number}</div>    
          }
      </div>
      
      <div className="text-left">
        <button type="submit" disabled={disablebutton}>Send Message</button>
      </div>
    </form>
    </>
  );
 
}
export default Contact_form;

